import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import readXlsxFile from "read-excel-file";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DropzoneArea } from "react-mui-dropzone";
import { COLORS } from "../../../helpers/Colors";
import { JSONToCSVConvertor } from "../../../helpers/excelHelpers";
import LoadingInModal from "./LoadingInModal";
import UserErrorSuccessFormDisplay from "../../../reusable/components/UserErrorSuccessFormDisplay";
import { createBulkLinks } from "../api";
import moment from "moment";
import { getDivisionTemplatesForBulkSMS } from "../api/getDivisionTemplatesForBulkSMS";

const useStyles = makeStyles((theme) => ({
  body: {
    width: "90%",
    color: COLORS.brightBlack,
    margin: "10px auto",
    textAlign: "center",
    minHeight: "350px",
  },
  title: {
    fontFamily: "Avenir-Heavy",
    fontSize: "1.2rem",
    margin: "10px",
  },
  actionButton: {
    fontFamily: "Avenir-Medium",
    cursor: "Pointer",
    background: COLORS.meliorYellow,
    color: COLORS.black,
    textTransform: "none",
    borderRadius: "10px",
    margin: "10px 0px",
    padding: "8px 20px",
    width: "250px",
    "&:hover": {
      background: COLORS.meliorYellowGentle,
    },
  },
  notice: {
    margin: "20px 0px",
  },
  dropZoneText: {
    fontSize: "16px",
    margin: "30px",
    fontFamily: "Avenir-Medium",
  },
  dropZoneContainer: {
    height: "fit-content",
    minHeight: "200px",
    padding: "10px",
    borderColor: COLORS.meliorYellow,
  },
  feedback: {
    height: "110px",
    width: "100%",
  },
  recordsContainer: {
    marginTop: "10px",
  },
  record: {
    border: `1px solid ${COLORS.greyGentle}`,
  },
  smsInfoTitle: {
    fontSize: "14px",
    margin: "8px 0",
  },
  smallActionButton: {
    fontFamily: "Avenir-Medium",
    cursor: "Pointer",
    color: COLORS.black,
    textTransform: "none",
    borderRadius: "10px",
    margin: "0",
    padding: "3px 6px",
    textDecoration: "underline",
    fontWeight: "600",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export const BulkGenerateLinksModalBody = ({
  branchName,
  divisionName,
  branchId,
  divisionId,
  setError,
  surveyVariant,
}) => {
  const classes = useStyles();
  const [numbersWithVisitDate, setNumbersWithVisitDate] = useState([]);
  const [generatedLinks, setGeneratedLinks] = useState(null);
  const [recordsFound, setRecordsFound] = useState(0);
  const [numbersMissingZeroWithVisitDate, setNumbersMissingZeroWithVisitDate] =
    useState([]);
  const [numbersInvalidCountOrForm, setNumbersInvalidCountOrForm] = useState(
    []
  );
  const [templatesOptions, setTemplatesOptions] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();

  const [done, setDone] = useState(false);
  const [parsed, setParsed] = useState(false);

  const [parsingNumbersError, setParsingNumbersError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [loading, setLoading] = useState(false);
  const [sheet, setSheet] = useState(null);

  useEffect(() => {
    setParsed(false);
    setNumbersWithVisitDate([]);
    setNumbersInvalidCountOrForm([]);
    setNumbersMissingZeroWithVisitDate([]);
    setParsingNumbersError({ isError: false, errorMessage: "" });
    setRecordsFound(0);
    setError({ isError: false, errorMessage: "" });
    setLoading(false);
    if (!sheet) return;
    parseNumbersFomSheet();
  }, [sheet]);

  useEffect(() => {
    if (recordsFound !== numbersWithVisitDate.length) {
      setParsingNumbersError({
        isError: true,
        errorMessage: "We found some invalid numbers",
      });
    } else {
      setParsingNumbersError({
        isError: false,
        errorMessage: "",
      });
    }
  }, [recordsFound, numbersWithVisitDate]);

  const parseNumbersFomSheet = () => {
    let currentNumbersInvalidCountOrForm = [];
    let currentNumbersMissingZeroWithVisitDate = [];
    let currentRecordsFound = 0;
    try {
      readXlsxFile(sheet)
        .then((currSheet) => {
          let rowNames = currSheet[0];
          let columnIndexOfPhoneNumber = rowNames.indexOf(
            rowNames.find(
              (rowName) =>
                rowName.toLowerCase().includes("phone") ||
                rowName.toLowerCase().includes("mobile")
            )
          );
          let columnIndexOfVisitDate = rowNames.indexOf(
            rowNames.find(
              (rowName) =>
                rowName?.toLowerCase().includes("visit") ||
                rowName?.toLowerCase().includes("date")
            )
          );
          if (columnIndexOfPhoneNumber === -1) {
            setError({
              isError: true,
              errorMessage: `We couldn't find a column header with "phone numbers" or "mobile numbers"`,
            });
          }

          var numbersWithVisitDateTemp = currSheet.reduce(
            (accumlator, currRow) => {
              const currentNumber =
                currRow[columnIndexOfPhoneNumber]?.toString();
              const currentVisitDate =
                columnIndexOfVisitDate !== -1
                  ? currRow[columnIndexOfVisitDate]?.toString()
                  : undefined;
              const formattedVisitDate = currentVisitDate
                ? moment(currentVisitDate).utc().format("YYYY-MM-DD HH:mm")
                : undefined;
              if (
                !!currentNumber &&
                currentNumber?.trim().length &&
                currentNumber !== rowNames[columnIndexOfPhoneNumber]
              ) {
                currentRecordsFound += 1;
                if (
                  (currentNumber.length === 11 && currentNumber[0] === "0") ||
                  (currentNumber.length === 12 &&
                    currentNumber.substring(0, 2) === "20") ||
                  (currentNumber.length === 13 &&
                    currentNumber.substring(0, 3) === "+20")
                ) {
                  accumlator.push({
                    mobile_number: currentNumber,
                    visit_date: formattedVisitDate,
                  });
                } else {
                  if (currentNumber.length === 10 && currentNumber[0] === "1") {
                    currentNumbersMissingZeroWithVisitDate.push({
                      mobile_number: currentNumber,
                      visit_date: formattedVisitDate,
                    });
                  } else {
                    currentNumbersInvalidCountOrForm.push(currentNumber);
                  }
                }
              }
              return accumlator;
            },
            []
          );
          setNumbersWithVisitDate(numbersWithVisitDateTemp);
          setNumbersInvalidCountOrForm(currentNumbersInvalidCountOrForm);
          setNumbersMissingZeroWithVisitDate(
            currentNumbersMissingZeroWithVisitDate
          );
          setRecordsFound(currentRecordsFound);
          setParsed(true);
        })
        .catch((error) => {
          console.log(error, "errprrrrrrrrr");
          setError({
            isError: true,
            errorMessage:
              "We couldn't parse your sheet, please re-create your sheet and try again",
          });
        });
    } catch (exception) {
      setError({
        isError: true,
        errorMessage:
          "We couldn't parse your sheet, please re-create your sheet and try again",
      });
    }
  };

  const generateLinks = () => {
    setLoading(true);
    createBulkLinks({
      branchId: branchId,
      divisionId: divisionId,
      templateId: selectedTemplate,
      numbersWithVisitDate: numbersWithVisitDate,
      type: surveyVariant,
    })
      .then((response) => {
        setLoading(false);
        setDone(true);
        setError({
          isError: false,
          errorMessage: "",
        });
        setGeneratedLinks(response.links);
      })
      .catch((error) => {
        if (error?.response?.status === 423) {
          setError({
            isError: true,
            errorMessage:
              "A sheet is in progress right now, please try again in a while",
          });
        } else {
          setError({
            isError: true,
            errorMessage: error?.response?.data.non_field_errors
              ? error.response.data.non_field_errors[0]
              : "Request failed, please try again later.",
          });
        }
        setLoading(false);
      });
  };

  const prefillWithZero = () => {
    setNumbersWithVisitDate([
      ...numbersWithVisitDate,
      ...numbersMissingZeroWithVisitDate.map((numberWithVisitDate) => ({
        ...numberWithVisitDate,
        mobile_number: `0${numberWithVisitDate.mobile_number}`,
      })),
    ]);
    setNumbersMissingZeroWithVisitDate([]);
    if (!numbersInvalidCountOrForm.length) {
      setParsingNumbersError({ isError: false, errorMessage: "" });
    }
  };

  const downloadGeneratedLinks = () => {
    JSONToCSVConvertor(
      generatedLinks,
      `${branchName} - ${divisionName} - Generated - Links - ${new Date().toLocaleDateString()}`,
      "Generated Links",
      "Mobile Number,Link"
    );
  };

  const MobileNumbersLinksTable = () => (
    <div style={{ maxHeight: 400, width: "100%" }}>
      <TableContainer component={Paper}>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Mobile Number</TableCell>
              <TableCell align="left">Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {generatedLinks.map((row, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {row[0]}
                </TableCell>
                <TableCell align="left">{row[1]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  const updateTemplateHandler = (event) => {
    setSelectedTemplate(event.target.value);
  };

  useEffect(() => {
    getDivisionTemplatesForBulkSMS(divisionId).then((divisionTemplates) => {
      const templatesOptionsTemp = divisionTemplates.map((templateData) => {
        return {
          label: templateData.name,
          value: templateData.id,
        };
      });

      setTemplatesOptions([...templatesOptionsTemp]);
      setSelectedTemplate(divisionTemplates[0].id);
    });
  }, [divisionId]);

  return (
    <div className={classes.body}>
      <h4 className={classes.title}>Multiple Link Generator</h4>
      {!numbersWithVisitDate.length && loading && !done && (
        <LoadingInModal height="30px" />
      )}
      {!generatedLinks?.length && loading && <LoadingInModal height="30px" />}

      {templatesOptions.length > 0 && (
        <Box mb={2} mt={2}>
          <FormControl sx={{ width: "200px" }}>
            <InputLabel id="tempalte">Template</InputLabel>
            <Select
              labelId="tempalte"
              id="demo-simple-select"
              defaultValue={templatesOptions[0].value}
              value={selectedTemplate}
              label="Template"
              onChange={updateTemplateHandler}
              disabled={templatesOptions.length === 1}
              title={
                templatesOptions.length === 1 ? "Only 1 Template exists" : ""
              }
            >
              {templatesOptions.map((templateOption) => {
                return (
                  <MenuItem value={templateOption.value}>
                    {templateOption.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      )}
      {!generatedLinks && !loading && (
        //   TODO use another library because this one depends on mui v4
        <DropzoneArea
          onChange={(file) => {
            setSheet(file[0]);

            setDone(false);
          }}
          dropzoneText={`Drag and Drop your excel sheet here. The column that contains the numbers must have a header title of 'Phone Numbers' or 'Mobile Numbers'. And the optional column for Visit Date must contain a 'Visit Date' title.`}
          acceptedFiles={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
          ]}
          dropzoneParagraphClass={classes.dropZoneText}
          useChipsForPreview={true}
          filesLimit={1}
          dropzoneClass={classes.dropZoneContainer}
          Icon={CloudUploadOutlinedIcon}
          showPreviews={false}
          showPreviewsInDropzone={false}
        />
      )}

      <div className={classes.feedback}>
        {!numbersWithVisitDate.length && !done && !loading && (
          <UserErrorSuccessFormDisplay
            bg={COLORS.meliorYellowGentle}
            type="info"
            message={`All Mobile Numbers should start with +20, 20 or 0.`}
            width="100%"
            margin="10px auto"
          />
        )}
        <Grid container alignItems="center" justifyContent="space-between">
          {numbersWithVisitDate.length > 0 && (
            <>
              <p className={classes.notice}>
                {recordsFound} record{recordsFound !== 1 ? "s" : ""} found,{" "}
                {numbersWithVisitDate.length} of which{" "}
                {numbersWithVisitDate.length !== 1 ? "are" : "is"} valid
              </p>
            </>
          )}
          {!numbersWithVisitDate.length && !!parsed && (
            <UserErrorSuccessFormDisplay
              color={COLORS.brightBlack}
              type="error"
              message={
                recordsFound === 0
                  ? `No records found`
                  : `No valid records found`
              }
              width="100%"
              margin="10px auto"
            />
          )}
          {!loading && !done && numbersWithVisitDate.length > 0 && (
            <Tooltip title={`Send ${numbersWithVisitDate.length} SMS`}>
              <Button className={classes.actionButton} onClick={generateLinks}>
                {parsingNumbersError.isError
                  ? `Generate links for valid numbers`
                  : `Generate`}
              </Button>
            </Tooltip>
          )}
        </Grid>
        {done && (
          <UserErrorSuccessFormDisplay
            color={COLORS.white}
            bg={COLORS.successGreen}
            message="Generation successful."
            type="success"
            width="100%"
            margin="auto"
            padding="15px 15px"
          />
        )}
        {generatedLinks && (
          <Grid container direction="column">
            <Button
              className={classes.actionButton}
              onClick={downloadGeneratedLinks}
            >
              Download
            </Button>
            <MobileNumbersLinksTable />
          </Grid>
        )}
        {!!parsingNumbersError.isError && (
          <UserErrorSuccessFormDisplay
            color={COLORS.brightBlack}
            type="error"
            message={parsingNumbersError.errorMessage}
            width="100%"
            margin="10px auto"
          />
        )}
        {!!parsingNumbersError.isError &&
          !!numbersMissingZeroWithVisitDate.length && (
            <Grid container spacing={2} className={classes.recordsContainer}>
              <Grid container justifyContent="space-between">
                <p className={classes.smsInfoTitle}>
                  These number seem to miss a "0" at the beginning
                </p>
                <Tooltip
                  title="A common issue we found is that some file editors remove the 0 from number,
                if we think this is the issue, we can add a 0 to the beginning of these numbers"
                >
                  <Button
                    className={classes.smallActionButton}
                    onClick={prefillWithZero}
                  >
                    Prefill with 0
                  </Button>
                </Tooltip>
              </Grid>
              {numbersMissingZeroWithVisitDate.map((record, index) => (
                <Grid
                  key={`${record.mobile_number}${index}`}
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.record}
                  style={{ padding: "5px" }}
                >
                  {record.mobile_number}
                </Grid>
              ))}
            </Grid>
          )}
        {!!parsingNumbersError.isError &&
          !!numbersInvalidCountOrForm.length && (
            <Grid container spacing={2} className={classes.recordsContainer}>
              <Grid container justifyContent="space-between">
                <p className={classes.smsInfoTitle}>
                  These number seem to be in the wrong format
                </p>
              </Grid>
              {numbersInvalidCountOrForm?.map((number, index) => (
                <Grid
                  key={`${number}${index}`}
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.record}
                  style={{ padding: "5px" }}
                >
                  {number}
                </Grid>
              ))}
            </Grid>
          )}
        {!loading && !done && !!numbersWithVisitDate.length && (
          <Grid container spacing={2} className={classes.recordsContainer}>
            <Grid container justifyContent="space-between">
              <p className={classes.smsInfoTitle}>Valid numbers</p>
            </Grid>
            {numbersWithVisitDate.map((record, index) => (
              <Grid
                key={`${record.mobile_number}${index}`}
                item
                xs={12}
                sm={4}
                md={3}
                className={classes.record}
                style={{ padding: "5px" }}
              >
                {record.mobile_number}
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  );
};
