import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../helpers/constants";

const getListOfFacilityUsers = () => {
  const requestURL = `${API_URL}/users/get-usernames`;

  return melior.get(requestURL);
};

export const useGetListOfFacilityUsers = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["users-to-mention-in-notes"],
    queryFn: () => getListOfFacilityUsers(),
    keepPreviousData: true,
  });
};
