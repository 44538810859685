import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Skeleton,
  Typography,
} from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AddNewNote } from "../shared/AddNewNote";
import { useGetListOfFacilityUsers } from "../../api/getListOfFacilityUsers";
import { AuthenticationContext } from "../../../login/contexts/AuthenticationContext";
import { useAddReputationResponseNote } from "../../api/reputation-responses/addReputationResponseNote";
import { ReputationResponseActivityLogItem } from "./ReputationResponseActivityLogItem";
import { useTriggerEmailForUserMentionInReputationResponse } from "../../api/reputation-responses/triggerEmailUserMentionInReputationResponse";
import { useGetReputationResponseActivityLogs } from "../../api/reputation-responses/getReputationResponseActivityLogs";
import { GenericDialog } from "../../../../reusable/components/GenericDialog";
import { ReviewComplaint } from "../../../outbound-feedback";
import moment from "moment";
import {
  ReputationResponsePlatforms,
  ReputationResponseStatuses,
} from "../../types/reputation-reponses";
import { useReplyToReputationResponse } from "../../api/reputation-responses/replyToReputationResponse";
import { OpenInNew, Send } from "@mui/icons-material";
import AdminLogoSVG from "../../../../assets/admin-logo.svg";
import { useMarkReputationAsSeen } from "../../api/reputation-responses/markReputationResponseAsSeen";
import {
  NOTIFICATION_WIDTH,
  NotificationContext,
  NOTIFICATIONS_HORIZONTAL_POSITION,
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
} from "../../../../contexts/NotificationContext";

export const ReputationResponseTableRowDetails = ({
  reputationResponseDetails,
  isExpanded,
}) => {
  const [attemptedToMarkAsSeen, setAttemptedToMarkAsSeen] = useState(false);
  const markReputationResponseAsIDMutation = useMarkReputationAsSeen();
  const { canManageLabels } = useContext(AuthenticationContext);

  const reviewTitleText = reputationResponseDetails.review_details?.title;
  const reviewDescriptionText = reputationResponseDetails.review_details?.text;

  useEffect(
    function markReputationResponseAsSeenIfExpandedAndNotSeen() {
      if (
        isExpanded &&
        reputationResponseDetails.status ===
          ReputationResponseStatuses.Unattended &&
        !attemptedToMarkAsSeen
      ) {
        setAttemptedToMarkAsSeen(true);
        markReputationResponseAsIDMutation.mutateAsync({
          reputationResponseID: reputationResponseDetails.id,
        });
      }
    },
    [
      attemptedToMarkAsSeen,
      reputationResponseDetails.id,
      reputationResponseDetails.status,
      isExpanded,
      markReputationResponseAsIDMutation,
    ]
  );

  return (
    <Grid
      container
      columns={12}
      bgcolor={primaryColors.gray[50]}
      width="100%"
      height="500px"
    >
      <Grid
        item
        xs={7}
        py={2}
        height="100%"
        borderRight={`1px solid ${primaryColors.gray[300]}`}
      >
        <ReputationResponseCommentAndReply
          reputationResponseDetails={reputationResponseDetails}
        />
      </Grid>
      <Grid item xs={5} py={2} height="100%">
        <ActivityLogs
          reputationResponseID={reputationResponseDetails.id}
          reputationResponseBranchID={reputationResponseDetails.branch.id}
          reputationResponseUsername={
            reputationResponseDetails.name_or_username
          }
          reputationResponseReviewText={
            reviewTitleText
              ? `${reviewTitleText}. ${reviewDescriptionText}`
              : reviewDescriptionText
          }
          reputationResponseTimestamp={reputationResponseDetails.submitted_on}
          isExpanded={isExpanded}
          canManageLabels={canManageLabels}
        />
      </Grid>
    </Grid>
  );
};

function ActivityLogs({
  reputationResponseID,
  reputationResponseUsername,
  reputationResponseBranchID,
  reputationResponseReviewText,
  reputationResponseTimestamp,
  isExpanded,
  canManageLabels,
}) {
  //   const [selectedUserFilter, setSelectedUserFilter] = useState("");
  //   const [selecteTypeFilter, setSelecteTypeFilter] = useState("");
  const [selectedLogsFilterType, setSelectedLogsFilterType] = useState("");
  const fileInputRef = useRef(null);

  const {
    data: reputationResponseActivityLogsResponse,
    isLoading: isLoadingActivityLogs,
  } = useGetReputationResponseActivityLogs({
    params: { reputationResponseID },
    config: {
      enabled: isExpanded,
    },
  });

  const { data: facilityUsersToMentionResponse } =
    useGetListOfFacilityUsers();

  //   const attachFileToComplaintMutation = useAttachFileToComplaint();
  const addNewNoteMutation = useAddReputationResponseNote();
  const triggerEmailForUserMentionInReputationResponseMutation =
    useTriggerEmailForUserMentionInReputationResponse();

  //   const { fireNotification } = useContext(NotificationContext);

  const onChangeActivityLogsFilterType = (event) => {
    setSelectedLogsFilterType(event.target.value);
  };

  const noActivityLogsExist = (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography color={primaryColors.error[700]}>
        No actions yet....
      </Typography>
    </Box>
  );

  const filteredActivityLogs = !selectedLogsFilterType
    ? reputationResponseActivityLogsResponse?.data
    : reputationResponseActivityLogsResponse?.data.filter(
        function matchesSelectedActivityLogsFilterType(activityLogServerItem) {
          return selectedLogsFilterType.includes(activityLogServerItem.action);
        }
      );

  const activityLogsList = (
    <Box display="flex" flexDirection="column">
      {filteredActivityLogs?.map(function renderNoteItem(
        activityLogData,
        index
      ) {
        return (
          <ReputationResponseActivityLogItem
            key={index}
            itemIndex={index}
            withBorderBottom={filteredActivityLogs?.length - 1 !== index}
            reputationResponseID={reputationResponseID}
            activityLogItemData={activityLogData}
          />
        );
      })}
    </Box>
  );

  const activityLogsLoadingSkeleton = (
    <Box display="flex" flexDirection="column" gap={2}>
      {Array(3)
        .fill()
        .map(function renderActivityLogItemSkeleton(v, index) {
          return (
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              sx={{
                borderBottom:
                  index !== 2
                    ? `1px solid ${primaryColors.gray[300]}`
                    : undefined,
                pb: 2,
                mb: 2,
              }}
            >
              {/* Circle, Title, Email & Description */}
              <Box display="flex" gap={1}>
                <Skeleton variant="circular" width={30} height={30} />
                <Box display="flex" flexDirection="column">
                  <Skeleton variant="text" width={200} />
                  <Skeleton variant="text" width={150} />
                  <Skeleton variant="text" width={50} sx={{ mt: 2 }} />
                </Box>
              </Box>
              {/* Date */}

              <Skeleton variant="text" width={120} height={20} />
            </Box>
          );
        })}
    </Box>
  );

  const addNewNoteInstance = !facilityUsersToMentionResponse ? null : (
    <AddNewNote
      mutationCallback={function addReviewNoteHandler({
        text,
        callbackOnSuccess,
        mentionedUserID,
      }) {
        addNewNoteMutation
          .mutateAsync({
            reputationResponseID,
            replyTextToReputationResponse: text,
          })
          .then(function () {
            callbackOnSuccess();
          })
          .then(function triggerEmailForReputationResponseMentionHandler() {
            triggerEmailForUserMentionInReputationResponseMutation.mutateAsync({
              reputationResponseID,
              mentionText: text,
              mentionedUserID,
            });
          });
      }}
      facilityUsersToMention={facilityUsersToMentionResponse.data}
      isSubmitting={addNewNoteMutation.isLoading}
    />
  );

  const activityLogsFiltrationMenu = null;

  const bottomNode = (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" width="100%" justifyContent="flex-end">
        <Box position="relative" display="inline-block">
          <ComplaintOnlyDialog
            branchID={reputationResponseBranchID}
            username={reputationResponseUsername}
            complaintText={reputationResponseReviewText}
            timestamp={reputationResponseTimestamp}
            reputationReponseID={reputationResponseID}
          />
          {/* <LoadingButton
            loading={attachFileToComplaintMutation.isLoading}
            variant="text"
            sx={{
              textTransform: "none",
              color: primaryColors.base.black,
              textDecoration: "underline",
            }}
            endIcon={<FileOpenOutlined />}
            onClick={function () {
              fileInputRef.current.click();
            }}
          >
            Attach a File
          </LoadingButton>
          <Tooltip title="Max Size 2 MB - Allowed extensions (.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv)">
            <input
              type="file"
              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv"
              ref={fileInputRef}
              onChange={function validateAndAttachFileToComplaint(event) {
                const maxSizeInBytes = 2 * 1024 * 1024; // 5MB (change this value as needed)
                const allowedExtensions = [
                  "jpg",
                  "jpeg",
                  "png",
                  "pdf",
                  "doc",
                  "docx",
                  "xls",
                  "xlsx",
                  "csv",
                ];

                const fileSize = event.target.files[0].size;
                const selectedFileName =
                  event.target.files[0].name.toLowerCase();
                const selectedFileExtension = selectedFileName.substring(
                  selectedFileName.lastIndexOf(".") + 1
                );

                const selectedFileExtensionIsValid = allowedExtensions.includes(
                  selectedFileExtension
                );
                const fileSizeIsValid = fileSize <= maxSizeInBytes;

                if (!selectedFileExtensionIsValid) {
                  fireNotification({
                    title: "Selected file extension is not allowed",
                    description: `Allowed file extensions: (.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv)`,
                    type: NOTIFICATIONS_TYPE.error,
                    verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
                    horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
                    target: NOTIFICATIONS_TARGET.admin,
                    width: NOTIFICATION_WIDTH.small,
                  });
                } else if (!fileSizeIsValid) {
                  fireNotification({
                    title: "Exceeded max allowed size",
                    description: `Max allowed size is 2 MB per file`,
                    type: NOTIFICATIONS_TYPE.error,
                    verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
                    horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
                    target: NOTIFICATIONS_TARGET.admin,
                    width: NOTIFICATION_WIDTH.small,
                  });
                } else {
                  console.log("About to upload");
                  // Upload the file if there are no errors.
                  const formData = new FormData();
                  formData.append("file", event.target.files[0]);

                  attachFileToComplaintMutation.mutateAsync({
                    reputationResponseID,
                    formData,
                  });
                }
              }}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0,
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
            />
          </Tooltip> */}
        </Box>
      </Box>
      {addNewNoteInstance}
    </Box>
  );

  const noActivityLogs = filteredActivityLogs?.length === 0;

  return (
    <TableRowSection
      label="Activity Logs & Notes"
      bottomNode={bottomNode}
      nextToLabelNode={activityLogsFiltrationMenu}
    >
      {isLoadingActivityLogs
        ? // Show Skeleton if the status is Loading
          activityLogsLoadingSkeleton
        : noActivityLogs
        ? // After loading, show empty activity logs view if empty
          noActivityLogsExist
        : // Show the Activity Logs List if there's data
          activityLogsList}
    </TableRowSection>
  );
}

function TableRowSection({
  label,
  bottomNode,
  withSideBorders,
  nextToLabelNode,
  children,
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      borderLeft={
        withSideBorders ? `2px solid ${primaryColors.gray[200]}` : undefined
      }
      borderRight={
        withSideBorders ? `2px solid ${primaryColors.gray[200]}` : undefined
      }
      px={2}
      boxSizing="border-box"
      height="inherit"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="text-sm" fontWeight="bold" sx={{ pb: 2 }}>
          {label}
        </Typography>
        {nextToLabelNode}
      </Box>
      <Box
        py={2}
        height="inherit"
        sx={{
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "white",
          },
          "&::-webkit-scrollbar-thumb": {
            background: primaryColors.gray[300],
            borderRadius: "6px",
          },
        }}
        mb={1}
        px={2}
      >
        {children}
      </Box>
      <Box display="flex" justifyContent="center" width="100%">
        {bottomNode}
      </Box>
    </Box>
  );
}

function ReputationResponseCommentAndReply({ reputationResponseDetails }) {
  const [replyTextToReputationResponse, setReplyTextToReputationResponse] =
    useState();

  const reputationResponseIsFromGoogle =
    reputationResponseDetails.platform === ReputationResponsePlatforms.Google;

  const reviewerTitleText = reputationResponseDetails.review_details.title;
  const reviewerDescText = reputationResponseDetails.review_details.text;
  const reviewerTextDate = reputationResponseDetails.review_details.date;
  const reviewerLogo = reputationResponseDetails.avatar;

  const adminReplyText = reputationResponseDetails.reply_details?.text;
  const adminHasReplied = adminReplyText !== undefined;
  const adminReplyTextDate = reputationResponseDetails.reply_details?.date;

  const replyToReputationResponseMutation = useReplyToReputationResponse();

  const isSubmittingAdminReply = replyToReputationResponseMutation.isLoading;

  const replyToGoogleRepResponseInput = (
    <Box display="flex" flexDirection="column" gap={1} width="100%">
      <FormControl
        sx={{
          width: "auto",
          bgcolor: primaryColors.base.white,
        }}
        variant="filled"
      >
        <OutlinedInput
          id="outlined-adornment-password"
          type={"text"}
          placeholder="Add a reply here..."
          value={replyTextToReputationResponse}
          onChange={function updateNewNoteState(event) {
            setReplyTextToReputationResponse(event.target.value);
          }}
          onKeyDown={function (keyEvent) {
            if (!isSubmittingAdminReply && keyEvent.code === "Enter") {
              replyToReputationResponseMutation.mutateAsync({
                replyText: replyTextToReputationResponse,
                reputationResponseID: reputationResponseDetails.id,
              });
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              {isSubmittingAdminReply ? (
                <CircularProgress size={24} />
              ) : (
                <IconButton
                  onClick={function () {
                    replyToReputationResponseMutation
                      .mutateAsync({
                        replyText: replyTextToReputationResponse,
                        reputationResponseID: reputationResponseDetails.id,
                      })
                      .then(function clearReplyTextIfSuccessful() {});
                  }}
                  edge="end"
                  size="large"
                >
                  <Send sx={{ color: primaryColors.brand[600] }} />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );

  const bottomNodeForGoogleRepResponse = adminHasReplied
    ? null
    : replyToGoogleRepResponseInput;

  return (
    <TableRowSection
      label="Review and Public Responses"
      bottomNode={
        reputationResponseIsFromGoogle ? bottomNodeForGoogleRepResponse : null
      }
    >
      <Box display="flex" flexDirection="column" gap={3}>
        {/* Start: Reviewer's Comment */}
        {reviewerDescText !== "" && (
          <Box display="flex" gap={2} alignItems="center">
            <img
              src={reviewerLogo}
              alt="reviewer-img"
              width="40px"
              height="40px"
              style={{ borderRadius: "50%" }}
              title={reputationResponseDetails.name_or_username}
            />
            {/* Start: Title and Description Box */}
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              p={1}
              borderRadius={2}
              boxShadow={2}
              bgcolor={primaryColors.gray[200]}
              width="fit-content"
            >
              <Box display="flex" justifyContent="space-between">
                <Typography
                  variant="text-lg"
                  color={primaryColors.gray[900]}
                  fontWeight="bold"
                >
                  {reviewerTitleText}
                </Typography>
                <Typography variant="text-sm" color={primaryColors.gray[400]}>
                  {moment(reviewerTextDate).format(reputationResponseIsFromGoogle ? "h:mm A, DD/MM/YYYY" : "DD/MM/YYYY")}
                </Typography>
              </Box>
              <Typography variant="text-md" color={primaryColors.gray[900]}>
                {reviewerDescText}
              </Typography>
            </Box>
            {/* End: Title and Description Box */}
            {reputationResponseDetails.review_details.url && (
              <IconButton
                aria-label="delete"
                color="primary"
                href={reputationResponseDetails.review_details.url}
                target="_blank"
                title={`Open this Review's page to check it ${
                  !adminHasReplied && "or Reply to it"
                }`}
              >
                <OpenInNew />
              </IconButton>
            )}
          </Box>
        )}
        {/* End: Reviewer's Comment */}

        {/* Start: Admin's Reply */}
        {reputationResponseIsFromGoogle && adminHasReplied && (
          <Box
            display="flex"
            flexDirection="column"
            width="fit-content"
            alignSelf="end"
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Box
                p={1}
                borderRadius={2}
                boxShadow={2}
                bgcolor={primaryColors.gray[500]}
                width="fit-content"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  justifyContent="start"
                >
                  <Typography variant="text-sm" color={primaryColors.gray[300]}>
                    {moment(adminReplyTextDate).format("h:mm A, DD/MM/YYYY")}
                  </Typography>
                  <Typography
                    variant="text-md"
                    color={primaryColors.base.white}
                  >
                    {adminReplyText}
                  </Typography>
                </Box>
              </Box>
              <img
                src={AdminLogoSVG}
                alt="reviewer-img"
                width="40px"
                height="40px"
                style={{ borderRadius: "50%" }}
                title="Admin"
              />
            </Box>
          </Box>
        )}
        {/* Start: Admin's Reply */}
      </Box>
    </TableRowSection>
  );
}

// function flattenHierarchy(data) {
//   const flatArray = [];

//   function flatten(node) {
//     const flatNode = { ...node }; // Create a copy of the node to avoid mutating the original data
//     // delete flatNode.children; // Remove the 'children' property to flatten the hierarchy
//     flatArray.push(flatNode);

//     if (node.children && node.children.length > 0) {
//       node.children.forEach((child) => flatten(child)); // Recursively flatten children
//     }
//   }

//   data.forEach((root) => flatten(root)); // Start flattening from the root nodes

//   return flatArray;
// }

// function searchFlatArray(flatArray, searchText) {
//   searchText = searchText.toLowerCase(); // Convert search text to lowercase for case-insensitive search
//   return flatArray.filter((item) => {
//     // Check if any property (ID, name, name_ar, code) contains the partial search text
//     return (
//       item.id.toString().includes(searchText) ||
//       item.text.toLowerCase().includes(searchText) ||
//       item.text_ar.toLowerCase().includes(searchText) ||
//       item.code?.toLowerCase().includes(searchText)
//       //   item.path.toLowerCase().includes(searchText)
//     );
//   });
// }

function ComplaintOnlyDialog({
  branchID,
  timestamp,
  complaintText,
  username,
  reputationReponseID,
}) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { fireNotification } = useContext(NotificationContext);

  useEffect(() => {
    return function resetToIsNotSubmitted() {
      setIsSubmitted(false);
    };
  }, []);

  return (
    <GenericDialog
      dialogTitle="Save this response as a Complaint"
      triggerButton={
        <Button
          variant="text"
          sx={{
            textTransform: "none",
            color: primaryColors.brand[600],
            fontWeight: "bold",
            textDecoration: "underline",
            mt: 1,
          }}
        >
          Input as Complaint
        </Button>
      }
      maxWidth="xl"
      isDone={isSubmitted}
    >
      <Box py={2}>
        <ReviewComplaint
          externalBranchID={branchID}
          externalTimestamp={timestamp}
          externalComplaintText={complaintText}
          externalUsername={username}
          callbackHandlerAfterSubmission={() => {
            fireNotification({
              title: `Reputation Response of ID #${reputationReponseID} has been successfully saved as a Complaint`,
              type: NOTIFICATIONS_TYPE.success,
              verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
              target: NOTIFICATIONS_TARGET.admin,
              horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
              width: NOTIFICATION_WIDTH.medium,
            });
            setIsSubmitted(true);
          }}
          complaintOnly
        />
      </Box>
    </GenericDialog>
  );
}
