import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  primaryColors,
  secondaryColors,
} from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { useGetComplaintNotes } from "../../api/complaints/getComplaintNotes";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Check,
  CheckBoxOutlined,
  FileOpenOutlined,
  Groups,
  PersonAdd,
  Replay,
  ThumbUpOffAlt,
  TurnedIn,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { useAddComplaintNote } from "../../api/complaints/addComplaintNote";
import { useGetComplaintActivityLogs } from "../../api/complaints/getComplaintActivityLogs";
import { useUpdateComplaintResolveStatus } from "../../api/complaints/updateComplaintResolveStatus";
import { LoadingButton } from "@mui/lab";
import AudioPlayerComponent from "../../../../reusable/components/AudioPlayerComponent";
import { AddNewNote } from "../shared/AddNewNote";
import { useGetListOfFacilityUsers } from "../../api/getListOfFacilityUsers";
import { renderAudio } from "../../../../helpers/General";
import { useSendSatisfactionSurveySMS } from "../../api/complaints/sendSatisfactionSurveySMS";
import {
  NOTIFICATIONS_HORIZONTAL_POSITION,
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATION_WIDTH,
  NotificationContext,
} from "../../../../contexts/NotificationContext";
import {
  ConfirmationDialog,
  SearchableMultiSelect,
} from "../../../../reusable/components";
import { useAssignLabelToComplaint } from "../../api/complaints/assignLabelToComplaint";
import { useUnassignLabelToComplaint } from "../../api/complaints/unassignLabelFromComplaint";
import { useGetTeams } from "../../api/getTeams";
import { useAssignTeamToComplaint } from "../../api/complaints/assignTeamToComplaint";
import { useUnassignTeamToComplaint } from "../../api/complaints/unassignTeamFromComplaint";
import { useAssignFacilityUserToComplaint } from "../../api/complaints/assignFacilityUserToComplaint";
import { useUnassignFacilityUserToComplaint } from "../../api/complaints/unassignFacilityUserToComplaint";
import { useAssignMRNOrPatientIDToComplaint } from "../../api/complaints/assignMRNOrPatientIDToComplaint";
import { useAttachFileToComplaint } from "../../api/complaints/attachFileToComplaint";
import {
  ACTIVITY_LOG_TYPES,
  ComplaintActivityLogItem,
} from "./ComplaintActivityLogItem";
import { useMarkComplaintAsSeen } from "../../api/complaints/markComplaintAsSeen";
import { complaintStatus } from "../shared/useOverallViewFiltersState";
import { useGetLabelsTree } from "../../api/complaints/getLabelsTree";
import { AuthenticationContext } from "../../../login/contexts/AuthenticationContext";
import { useTriggerEmailForUserMentionInComplaint } from "../../api/complaints/triggerEmailUserMentionInComplaint";

export const ComplaintTableRowDetails = ({
  complaintDetails,
  isExpanded,
  isFetchingComplaintsList,
}) => {
  const [attemptedToMarkAsSeen, setAttemptedToMarkAsSeen] = useState(false);
  const markComplaintAsIDMutation = useMarkComplaintAsSeen();
  const { canManageLabels } = useContext(AuthenticationContext);

  useEffect(
    function markComplaintAsSeenIfExpandedAndNotSeen() {
      if (
        isExpanded &&
        complaintDetails.status === complaintStatus.Unattended &&
        !attemptedToMarkAsSeen
      ) {
        setAttemptedToMarkAsSeen(true);
        markComplaintAsIDMutation.mutateAsync({
          complaintID: complaintDetails.id,
        });
      }
    },
    [
      attemptedToMarkAsSeen,
      complaintDetails.id,
      complaintDetails.status,
      isExpanded,
      markComplaintAsIDMutation,
    ]
  );

  return (
    <Grid
      container
      columns={12}
      bgcolor={primaryColors.gray[50]}
      width="100%"
      height="500px"
    >
      <Grid item xs={4} py={2} height="100%">
        <PatientCommentWithResolvingMechanism
          complaintID={complaintDetails.id}
          complaintResolved={complaintDetails.resolved}
          satisfactionSurveySent={
            complaintDetails.satisfaction_survey_sent || false
          }
          commentData={{
            patientName: complaintDetails.contacts?.name,
            complaintDate: complaintDetails.submitted_at,
            commentText: complaintDetails.comment_text,
            commentAudio: complaintDetails.comment_audio,
          }}
        />
      </Grid>
      <Grid item xs={4} py={2} height="100%">
        <ComplaintInputs
          complaintID={complaintDetails.id}
          complaintLabels={complaintDetails.labels}
          relatedTeams={complaintDetails.related_teams}
          assignedFacilityUsers={complaintDetails.assignees}
          savedPatientIDOrMRN={complaintDetails.mrn}
          isExpanded={isExpanded}
          canManageLabels={canManageLabels}
          //   isFetchingComplaintsList={isFetchingComplaintsList}
        />
      </Grid>
      <Grid item xs={4} py={2} height="100%">
        <ActivityLogs
          complaintID={complaintDetails.id}
          isExpanded={isExpanded}
          canManageLabels={canManageLabels}
        />
      </Grid>
    </Grid>
  );
};

const PatientCommentWithResolvingMechanism = ({
  satisfactionSurveySent,
  complaintResolved,
  complaintID,
  commentData,
}) => {
  const commentText = commentData.commentText !== undefined &&
    commentData.commentText !== "" && (
      <Typography
        color={primaryColors.base.black}
        variant="text-md"
        sx={{ fontWeight: fontWeightVariations.medium }}
      >
        {commentData.commentText !== undefined && commentData.commentText !== ""
          ? commentData.commentText
          : "......"}
      </Typography>
    );

  const commentAudio =
    commentData.commentAudio && renderAudio(commentData.commentAudio);

  const ResolveComplaintWithSurveyFeedback = () => {
    const resolveComplaintMutation = useUpdateComplaintResolveStatus();

    const sendSatisfactionSurveySMSMutation = useSendSatisfactionSurveySMS();

    const reopenComplaintButton = (
      <LoadingButton
        startIcon={<Replay />}
        variant="contained"
        sx={{
          boxShadow: 1,
          textTransform: "capitalize",
          fontWeight: fontWeightVariations.semiBold,
          width: "80%",
          height: "48px",
          bgcolor: primaryColors.base.white,
          color: primaryColors.error[700],
          "&:hover": {
            bgcolor: primaryColors.base.white,
          },
        }}
        loading={resolveComplaintMutation.isLoading}
        onClick={function resolveComplaintHandler() {
          resolveComplaintMutation.mutateAsync({
            complaintID: complaintID,
            resolved: !complaintResolved,
          });
        }}
      >
        Reopen
      </LoadingButton>
    );

    const resolveComplaintButton = (
      <LoadingButton
        startIcon={<Check />}
        variant="contained"
        sx={{
          boxShadow: 1,
          textTransform: "capitalize",
          fontWeight: fontWeightVariations.semiBold,
          width: "80%",
          height: "48px",
          bgcolor: primaryColors.success[500],
          color: primaryColors.base.white,
          "&:hover": {
            bgcolor: primaryColors.success[500],
          },
        }}
        loading={resolveComplaintMutation.isLoading}
        onClick={function resolveComplaintHandler() {
          resolveComplaintMutation.mutateAsync({
            complaintID: complaintID,
            resolved: !complaintResolved,
          });
        }}
      >
        Resolve
      </LoadingButton>
    );

    const resolvedComplaintView = (
      <Box display="flex" alignItems="center" gap={2} width="100%">
        <LoadingButton
          title={
            satisfactionSurveySent
              ? "Satisfaction Survey SMS has been sent already"
              : "Send a Satisfaction Survey SMS to the attached phone number to this Complaint"
          }
          startIcon={<ThumbUpOffAlt />}
          variant="contained"
          // disabled={satisfactionSurveySent}
          sx={{
            boxShadow: 1,
            textTransform: "capitalize",
            fontWeight: fontWeightVariations.semiBold,
            width: "100%",
            height: "48px",
            bgcolor: primaryColors.gray[500],
            color: primaryColors.base.white,
            cursor: satisfactionSurveySent ? "not-allowed" : "pointer",
            opacity: satisfactionSurveySent ? 0.5 : "default",
            "&:hover": {
              bgcolor: primaryColors.gray[400],
            },
          }}
          loading={sendSatisfactionSurveySMSMutation.isLoading}
          onClick={function sendSatisfactionSurveyHandler() {
            !satisfactionSurveySent &&
              sendSatisfactionSurveySMSMutation.mutateAsync({
                complaintID: complaintID,
              });
          }}
        >
          Send Satisfaction Survey
        </LoadingButton>

        {reopenComplaintButton}
      </Box>
    );

    const openComplaintView = resolveComplaintButton;

    return complaintResolved ? resolvedComplaintView : openComplaintView;
  };

  return (
    <TableRowSection
      label="Comment"
      bottomNode={<ResolveComplaintWithSurveyFeedback />}
    >
      <Box display="flex" flexDirection={"column"} gap={4}>
        {/* Start: Comment Text */}
        <Box display="flex" flexDirection="column" px={2} gap={0.5}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{ color: primaryColors.gray[700] }}
              variant="text-sm"
            >
              {commentData.patientName}
            </Typography>
            <Typography
              sx={{ color: primaryColors.gray[500] }}
              variant="text-sm"
            >
              {moment(commentData.complaintDate).format("h:mm A, DD/MM/YYYY")}
            </Typography>
          </Box>
          <Box
            px="14px"
            py="10px"
            borderRadius="8px"
            bgcolor={primaryColors.gray[200]}
            width="auto"
            display="flex"
            flexDirection="column"
            gap={2}
          >
            {commentText}
            {commentAudio}
          </Box>
        </Box>
        {/* End: Comment Text */}

        {/* Start: Comment Audio */}
        {commentData.comment_audio && (
          <AudioPlayerComponent src={commentData.comment_audio} size={true} />
        )}
        {/* End: Comment Audio */}
      </Box>
    </TableRowSection>
  );
};

const ComplaintInputs = ({
  complaintID,
  complaintLabels,
  assignedFacilityUsers,
  savedPatientIDOrMRN,
  relatedTeams,
  canManageLabels,
  isExpanded,
}) => {
  const [classificationLabels, setClassificationLabels] = useState([]);
  const [patientIDOrMRNState, setPartientIDOrMRNState] = useState();
  const [userOptionToBeSelected, setUserOptionToBeAssigned] = useState("");

  const confirmComplaintUserAssignationButtonRef = useRef();

  

  const assignLabelToComplaintMutation = useAssignLabelToComplaint();
  const unassignLabelToComplaintMutation = useUnassignLabelToComplaint();
  const labelAssigningLoadingState = !assignLabelToComplaintMutation.isIdle;
  const labelUnassigningLoadingState = !unassignLabelToComplaintMutation.isIdle;
  const labelingAssignationIsLoading =
    labelAssigningLoadingState || labelUnassigningLoadingState;

  const assignTeamToComplaintMutation = useAssignTeamToComplaint();
  const unassignTeamToComplaintMutation = useUnassignTeamToComplaint();
  const assigningTeamIsLoading = !assignTeamToComplaintMutation.isIdle;
  const unassigningTeamIsLoading = !unassignTeamToComplaintMutation.isIdle;
  const teamAssignationIsLoading =
    assigningTeamIsLoading || unassigningTeamIsLoading;

  const assignFacilityUserToComplaintMutation =
    useAssignFacilityUserToComplaint();
  const unassignFacilityUserToComplaintMutation =
    useUnassignFacilityUserToComplaint();
  const assigningFacilityUserIsLoading =
    !assignFacilityUserToComplaintMutation.isIdle;
  const unassigningFacilityUserIsLoading =
    !unassignFacilityUserToComplaintMutation.isIdle;
  const facilityUserAssignationIsLoading =
    assigningFacilityUserIsLoading || unassigningFacilityUserIsLoading;

  const assignMRNOrPatientIDToComplaintMutation =
    useAssignMRNOrPatientIDToComplaint();
  const patienIDOrMRNAssignationIsLoading =
    !assignMRNOrPatientIDToComplaintMutation.isIdle;

  const { data: teamsResponse, isFetching: isFetchingTeamsData } = useGetTeams(
    {}
  );
  const {
    data: facilityUsersToMentionResponse,
    isFetching: isFetchingFacilityUsersData,
  } = useGetListOfFacilityUsers();

  const { data: labelsTreeServerResponse, isFetching: isFetchingLabelsTree } =
    useGetLabelsTree({ config: { enabled: canManageLabels } });

  function mapLabelItemToAnOption(labelItem) {
    if (labelItem) {
      return {
        label: labelItem.text,
        value: labelItem.id,
        children: labelItem.children,
        // helperText: `${labelItem.code ?? "code"} - ${labelItem.path}`,
        customActions: labelItem.children?.length > 0 && (
          <Button
            title="Show related labels"
            size="small"
            onClick={function (e) {
              e.stopPropagation();
              const updatedClassificationLabels = labelItem.children;
              setClassificationLabels([...updatedClassificationLabels]);
            }}
            sx={{
              textTransform: "capitalize",
              color: primaryColors.base.black,
              height: "fit-content",
            }}
            color="inherit"
            variant="outlined"
            startIcon={<AddIcon />}
          >
            <Typography variant="text-sm">Expand</Typography>
          </Button>
        ),
      };
    }
  }

  const flatLabelsForSearch = flattenHierarchy(classificationLabels);

  const patientIDOrMRNValueText = savedPatientIDOrMRN
    ? savedPatientIDOrMRN
    : "________";

  const patientIDOrMRNPlaceholder = patienIDOrMRNAssignationIsLoading ? (
    <Skeleton
      animation="pulse"
      width={"70px"}
      height="20px"
      sx={{ borderRadius: 2 }}
    />
  ) : (
    <Typography sx={{ opacity: 0.5 }}>
      Value: {patientIDOrMRNValueText}
    </Typography>
  );

  useEffect(
    function setClassificationLabelsStateWithServerData() {
      if (labelsTreeServerResponse) {
        setClassificationLabels([...labelsTreeServerResponse.data]);
      }
    },
    [labelsTreeServerResponse]
  );

  return (
    <TableRowSection withSideBorders>
      <Box display="flex" flexDirection="column" gap={3}>
        {/* Start: Label Classification */}
        {canManageLabels && (
          <SearchableMultiSelect
            label="Classify"
            placeholder="Browse or Search for Complaint's Classification(s)"
            staticOptions={classificationLabels.map(mapLabelItemToAnOption)}
            isLoading={isFetchingLabelsTree}
            onCloseMenuHandler={function resetCalssificationLabels() {
              setClassificationLabels([...labelsTreeServerResponse?.data]);
            }}
            filterOptionsHandler={function filterAllLabels(
              options,
              { inputValue }
            ) {
              const hierarchialLabels = [...classificationLabels].map(
                mapLabelItemToAnOption
              );

              const filteredFlatLabels = searchFlatArray(
                flatLabelsForSearch,
                inputValue
              ).map(mapLabelItemToAnOption);
              return inputValue === "" ? hierarchialLabels : filteredFlatLabels;
            }}
            isLoadingSelectedOptionsProp={labelingAssignationIsLoading}
            onSelectHandler={function ({ selectedOption, closeMenu }) {
              console.log(selectedOption);
              if (selectedOption.children.length > 0) {
                // Expand

                const updatedClassificationLabels = selectedOption.children;
                setClassificationLabels([...updatedClassificationLabels]);
              } else {
                // Select

                assignLabelToComplaintMutation.mutateAsync({
                  complaintID,
                  newLabelID: selectedOption.value,
                });

                setClassificationLabels([...labelsTreeServerResponse?.data]);
                closeMenu();
              }
            }}
            onRemoveHandler={function (labelIDToRemove) {
              unassignLabelToComplaintMutation.mutateAsync({
                complaintID,
                labelIDToRemove,
              });
            }}
            selectedOptionsProp={complaintLabels.map(
              function mapLabelItemToSelectOption(labelItem) {
                return {
                  label: labelItem.text,
                  value: labelItem.id,
                  helperText: labelItem.path,
                };
              }
            )}
            emptySearchValueHandler={function resetOptionsWhenSearchValueIsEmpty() {
              setClassificationLabels([...labelsTreeServerResponse.data]);
            }}
            endIconProp={<TurnedIn sx={{ color: primaryColors.brand[600] }} />}
            temporarilyCancelSelection
          />
        )}
        {/* End: Label Classification */}

        {/* Start: Team Assignation */}
        <SearchableMultiSelect
          label="Related Teams"
          placeholder="Choose related team(s)"
          isLoading={isFetchingTeamsData}
          staticOptions={teamsResponse?.data.map(function mapTeamItemToAnOption(
            teamItem
          ) {
            return {
              label: teamItem.name,
              value: teamItem.id,
            };
          })}
          isLoadingSelectedOptionsProp={teamAssignationIsLoading}
          onSelectHandler={function ({ selectedOption, closeMenu }) {
            assignTeamToComplaintMutation.mutateAsync({
              complaintID,
              newTeamID: selectedOption.value,
            });
            closeMenu();
          }}
          onRemoveHandler={function (teamIDToRemove) {
            unassignTeamToComplaintMutation.mutateAsync({
              complaintID,
              teamIDToRemove: teamIDToRemove,
            });
          }}
          selectedOptionsProp={relatedTeams.map(
            function mapTeamItemToSelectOption(teamItem) {
              return { label: teamItem.name, value: teamItem.id };
            }
          )}
          endIconProp={<Groups sx={{ color: primaryColors.brand[600] }} />}
        />
        {/* End: Team Assignation */}

        {/* Start: Facility Users Assignation */}
        <ConfirmationDialog
          title={`Confirmation`}
          subtitle={`Are you sure you want to assign Complaint (#${complaintID}) to (${userOptionToBeSelected.label})?`}
          onConfirmationCallback={function () {
            console.log("NICE");
            assignFacilityUserToComplaintMutation.mutateAsync({
              complaintID,
              newFacilityUserID: userOptionToBeSelected.value,
            });
          }}
          isDone={assignFacilityUserToComplaintMutation.isSuccess}
          isSubmitting={assignFacilityUserToComplaintMutation.isLoading}
          triggerButton={
            <Button
              ref={confirmComplaintUserAssignationButtonRef}
            >
              confirm assignation
            </Button>
          }
          isHidden
        />
        <SearchableMultiSelect
          label="Assign to"
          placeholder="Select Facility User(s)"
          isLoading={isFetchingFacilityUsersData}
          staticOptions={facilityUsersToMentionResponse?.data.map(
            function mapFacilityUserItemToAnOption(facilityUser) {
              return {
                label: facilityUser.username,
                value: facilityUser.id,
              };
            }
          )}
          isLoadingSelectedOptionsProp={facilityUserAssignationIsLoading}
          onSelectHandler={function ({ selectedOption, closeMenu }) {
            setUserOptionToBeAssigned(selectedOption)
            confirmComplaintUserAssignationButtonRef.current.click();
            // assignFacilityUserToComplaintMutation.mutateAsync({
            //   complaintID,
            //   newFacilityUserID: selectedOption.value,
            // });
            closeMenu();
          }}
          onRemoveHandler={function (userIDToRemove) {
            unassignFacilityUserToComplaintMutation.mutateAsync({
              complaintID,
              facilityUserIDToRemove: userIDToRemove,
            });
          }}
          selectedOptionsProp={assignedFacilityUsers.map(
            function mapFacilityUserItemToSelectOption(facilityUser) {
              return { label: facilityUser.username, value: facilityUser.id };
            }
          )}
          endIconProp={<PersonAdd sx={{ color: primaryColors.brand[600] }} />}
        />
        {/* End: Facility Users Assignation */}

        {/* Start: MRN or Patient ID assignation */}
        <Box>
          <Typography
            variant={fontSizeVariations["text-lg"]}
            fontWeight={fontWeightVariations.bold}
          >
            Medical Record Number (MRN) or Patient ID
          </Typography>
          <Paper
            component="div"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{
                ml: 1,
                flex: 1,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderWidth: 0,
                  },
                  "&:hover fieldset": {
                    borderWidth: 0,
                  },
                  "&.Mui-focused fieldset": {
                    borderWidth: 0,
                  },
                },
              }}
              placeholder="Add MRN or Patient ID"
              inputProps={{ "aria-label": "search google maps" }}
              value={patientIDOrMRNState}
              onKeyDown={function detectEnterKeyAndSaveMRN(e) {
                if (e.key === "Enter") {
                  assignMRNOrPatientIDToComplaintMutation
                    .mutateAsync({
                      complaintID,
                      newPatientIDOrMRN: e.target.value,
                    })
                    .then(function resetMRNStateValue() {
                      setPartientIDOrMRNState("");
                    });
                }
              }}
              onChange={function updateMRNState(e) {
                setPartientIDOrMRNState(e.target.value);
              }}
            />
            <Divider
              sx={{ height: 28, m: 0.5, mr: 1 }}
              orientation="vertical"
            />
            {patientIDOrMRNPlaceholder}
            <Divider
              sx={{ height: 28, m: 0.5, ml: 1 }}
              orientation="vertical"
            />
            <IconButton
              sx={{ p: "10px", color: primaryColors.brand[600] }}
              title="Click to Save"
              aria-label="directions"
              type="button"
              onClick={function savePatientIDOrMRNValueToComplaint() {
                assignMRNOrPatientIDToComplaintMutation
                  .mutateAsync({
                    complaintID,
                    newPatientIDOrMRN: patientIDOrMRNState,
                  })
                  .then(function resetMRNStateValue() {
                    setPartientIDOrMRNState("");
                  });
              }}
            >
              {assignMRNOrPatientIDToComplaintMutation.isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <CheckBoxOutlined />
              )}
            </IconButton>
          </Paper>
        </Box>
        {/* End: MRN or Patient ID assignation */}
      </Box>
    </TableRowSection>
  );
};

const ActivityLogs = ({ complaintID, isExpanded, canManageLabels }) => {
  //   const [selectedUserFilter, setSelectedUserFilter] = useState("");
  //   const [selecteTypeFilter, setSelecteTypeFilter] = useState("");
  const [selectedLogsFilterType, setSelectedLogsFilterType] = useState("");
  const fileInputRef = useRef(null);

  const {
    data: complaintActivityLogsResponse,
    isLoading: isLoadingActivityLogs,
  } = useGetComplaintActivityLogs({
    params: { complaintID },
    config: {
      enabled: isExpanded,
    },
  });

  const { data: facilityUsersToMentionResponse } =
    useGetListOfFacilityUsers();

  const attachFileToComplaintMutation = useAttachFileToComplaint();
  const addNewNoteMutation = useAddComplaintNote();
  const triggerEmailForUserMentionInComplaintMutation =
    useTriggerEmailForUserMentionInComplaint();

  const { fireNotification } = useContext(NotificationContext);

  const onChangeActivityLogsFilterType = (event) => {
    setSelectedLogsFilterType(event.target.value);
  };

  const noActivityLogsExist = (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography color={primaryColors.error[700]}>
        No actions yet....
      </Typography>
    </Box>
  );

  const filteredActivityLogs = !selectedLogsFilterType
    ? complaintActivityLogsResponse?.data
    : complaintActivityLogsResponse?.data.filter(
        function matchesSelectedActivityLogsFilterType(activityLogServerItem) {
          return selectedLogsFilterType.includes(activityLogServerItem.action);
        }
      );

  const activityLogsList = (
    <Box display="flex" flexDirection="column">
      {filteredActivityLogs?.map(function renderNoteItem(
        activityLogData,
        index
      ) {
        return (
          <ComplaintActivityLogItem
            key={index}
            itemIndex={index}
            withBorderBottom={filteredActivityLogs?.length - 1 !== index}
            complaintID={complaintID}
            activityLogItemData={activityLogData}
          />
        );
      })}
    </Box>
  );

  const activityLogsLoadingSkeleton = (
    <Box display="flex" flexDirection="column" gap={2}>
      {Array(3)
        .fill()
        .map(function renderActivityLogItemSkeleton(v, index) {
          return (
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              sx={{
                borderBottom:
                  index !== 2
                    ? `1px solid ${primaryColors.gray[300]}`
                    : undefined,
                pb: 2,
                mb: 2,
              }}
            >
              {/* Circle, Title, Email & Description */}
              <Box display="flex" gap={1}>
                <Skeleton variant="circular" width={30} height={30} />
                <Box display="flex" flexDirection="column">
                  <Skeleton variant="text" width={200} />
                  <Skeleton variant="text" width={150} />
                  <Skeleton variant="text" width={50} sx={{ mt: 2 }} />
                </Box>
              </Box>
              {/* Date */}

              <Skeleton variant="text" width={120} height={20} />
            </Box>
          );
        })}
    </Box>
  );

  const addNewNoteInstance = !facilityUsersToMentionResponse ? null : (
    <AddNewNote
      mutationCallback={function addReviewNoteHandler({
        text,
        callbackOnSuccess,
        mentionedUserID,
      }) {
        addNewNoteMutation
          .mutateAsync({ complaintID, newNoteText: text })
          .then(function () {
            callbackOnSuccess();
          })
          .then(function triggerEmailForComplaintMentionHandler() {
            triggerEmailForUserMentionInComplaintMutation.mutateAsync({
              complaintID,
              mentionText: text,
              mentionedUserID,
            });
          });
      }}
      facilityUsersToMention={facilityUsersToMentionResponse.data}
      isSubmitting={addNewNoteMutation.isLoading}
    />
  );

  const activityLogsFiltrationMenu = (
    <FormControl size="small" sx={{ width: "300px", mb: 1 }}>
      {/* <InputLabel id="demo-simple-select-label" sx={{
        fontSize: "14px",
        // fontWeight: "bold"
      }}>Activity Logs Type</InputLabel> */}
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedLogsFilterType}
        // label="Activity Logs Type"
        onChange={onChangeActivityLogsFilterType}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          height: "35px",
          fontSize: "14px",
        }}
      >
        <MenuItem disabled value="">
          <em>Filter by Type</em>
        </MenuItem>
        <MenuItem value={undefined}>Show All</MenuItem>
        <MenuItem value={`${ACTIVITY_LOG_TYPES.FileAttached}`}>
          Attachments
        </MenuItem>
        {canManageLabels && (
          <MenuItem
            value={`${ACTIVITY_LOG_TYPES.ClassifiedWithLabel}-${ACTIVITY_LOG_TYPES.LabelClassificationRemoved}`}
          >
            Classification
          </MenuItem>
        )}
        <MenuItem
          value={`${ACTIVITY_LOG_TYPES.AssignedToTeam}-${ACTIVITY_LOG_TYPES.AssignedTeamRemoved}`}
        >
          Related Teams
        </MenuItem>
        <MenuItem
          value={`${ACTIVITY_LOG_TYPES.AssignedToUser}-${ACTIVITY_LOG_TYPES.AssignedUserRemoved}`}
        >
          Assignment
        </MenuItem>
        <MenuItem value={`${ACTIVITY_LOG_TYPES.MRNUpdated}`}>
          MRN / Patient ID updates
        </MenuItem>
        <MenuItem
          value={`${ACTIVITY_LOG_TYPES.SatisfactionSurveySubmittedFromClient}`}
        >
          Satisfaction
        </MenuItem>
        <MenuItem
          value={`${ACTIVITY_LOG_TYPES.Resolved}-${ACTIVITY_LOG_TYPES.Unresolved}`}
        >
          Resolution
        </MenuItem>
        <MenuItem value={`${ACTIVITY_LOG_TYPES.NoteAdded}`}>
          Notes and Comments
        </MenuItem>
        {/* <MenuItem
          value={`${ACTIVITY_LOG_TYPES.Archived}-${ACTIVITY_LOG_TYPES.Unarchived}`}
        >
          Archiving
        </MenuItem> */}
      </Select>
    </FormControl>
  );

  const bottomNode = (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" width="100%" justifyContent="flex-end">
        <Box position="relative" display="inline-block">
          <LoadingButton
            loading={attachFileToComplaintMutation.isLoading}
            variant="text"
            sx={{
              textTransform: "none",
              color: primaryColors.base.black,
              textDecoration: "underline",
            }}
            endIcon={<FileOpenOutlined />}
            onClick={function () {
              fileInputRef.current.click();
            }}
          >
            Attach a File
          </LoadingButton>
          <Tooltip title="Max Size 2 MB - Allowed extensions (.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv, .opus, .mp3, .mp4, .wav, .webm)">
            <input
              type="file"
              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv, .opus, .mp3, .mp4, .wav, .webm"
              ref={fileInputRef}
              onChange={function validateAndAttachFileToComplaint(event) {
                const maxSizeInBytes = 2 * 1024 * 1024; // 5MB (change this value as needed)
                const allowedExtensions = [
                  "jpg",
                  "jpeg",
                  "png",
                  "pdf",
                  "doc",
                  "docx",
                  "xls",
                  "xlsx",
                  "csv",
                  "opus", 
                  "mp3", 
                  "mp4", 
                  "wav", 
                  "webm"
                ];

                const fileSize = event.target.files[0].size;
                const selectedFileName =
                  event.target.files[0].name.toLowerCase();
                const selectedFileExtension = selectedFileName.substring(
                  selectedFileName.lastIndexOf(".") + 1
                );

                const selectedFileExtensionIsValid = allowedExtensions.includes(
                  selectedFileExtension
                );
                const fileSizeIsValid = fileSize <= maxSizeInBytes;

                if (!selectedFileExtensionIsValid) {
                  fireNotification({
                    title: "Selected file extension is not allowed",
                    description: `Allowed file extensions: (.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv, .opus, .mp3, .mp4, .wav, .webm)`,
                    type: NOTIFICATIONS_TYPE.error,
                    verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
                    horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
                    target: NOTIFICATIONS_TARGET.admin,
                    width: NOTIFICATION_WIDTH.small,
                  });
                } else if (!fileSizeIsValid) {
                  fireNotification({
                    title: "Exceeded max allowed size",
                    description: `Max allowed size is 2 MB per file`,
                    type: NOTIFICATIONS_TYPE.error,
                    verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
                    horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
                    target: NOTIFICATIONS_TARGET.admin,
                    width: NOTIFICATION_WIDTH.small,
                  });
                } else {
                  console.log("About to upload");
                  // Upload the file if there are no errors.
                  const formData = new FormData();
                  formData.append("file", event.target.files[0]);

                  attachFileToComplaintMutation.mutateAsync({
                    complaintID,
                    formData,
                  });
                }
              }}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0,
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </Box>
      </Box>
      {addNewNoteInstance}
    </Box>
  );

  const noActivityLogs = filteredActivityLogs?.length === 0;

  return (
    <TableRowSection
      label="Activity Logs & Notes"
      bottomNode={bottomNode}
      nextToLabelNode={activityLogsFiltrationMenu}
    >
      {isLoadingActivityLogs
        ? // Show Skeleton if the status is Loading
          activityLogsLoadingSkeleton
        : noActivityLogs
        ? // After loading, show empty activity logs view if empty
          noActivityLogsExist
        : // Show the Activity Logs List if there's data
          activityLogsList}
    </TableRowSection>
  );
};

const TableRowSection = ({
  label,
  bottomNode,
  withSideBorders,
  nextToLabelNode,
  children,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      borderLeft={
        withSideBorders ? `2px solid ${primaryColors.gray[200]}` : undefined
      }
      borderRight={
        withSideBorders ? `2px solid ${primaryColors.gray[200]}` : undefined
      }
      px={2}
      boxSizing="border-box"
      height="inherit"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="text-sm" fontWeight="bold" sx={{ pb: 2 }}>
          {label}
        </Typography>
        {nextToLabelNode}
      </Box>
      <Box
        py={2}
        height="inherit"
        sx={{
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "white",
          },
          "&::-webkit-scrollbar-thumb": {
            background: primaryColors.gray[300],
            borderRadius: "6px",
          },
        }}
        mb={1}
        px={2}
      >
        {children}
      </Box>
      <Box display="flex" justifyContent="center" width="100%">
        {bottomNode}
      </Box>
    </Box>
  );
};

function flattenHierarchy(data) {
  const flatArray = [];

  function flatten(node) {
    const flatNode = { ...node }; // Create a copy of the node to avoid mutating the original data
    // delete flatNode.children; // Remove the 'children' property to flatten the hierarchy
    flatArray.push(flatNode);

    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => flatten(child)); // Recursively flatten children
    }
  }

  data.forEach((root) => flatten(root)); // Start flattening from the root nodes

  return flatArray;
}

function searchFlatArray(flatArray, searchText) {
  searchText = searchText.toLowerCase(); // Convert search text to lowercase for case-insensitive search
  return flatArray.filter((item) => {
    // Check if any property (ID, name, name_ar, code) contains the partial search text
    return (
      item.id.toString().includes(searchText) ||
      item.text.toLowerCase().includes(searchText) ||
      item.text_ar.toLowerCase().includes(searchText) ||
      item.code?.toLowerCase().includes(searchText)
      //   item.path.toLowerCase().includes(searchText)
    );
  });
}
