export const JSONToCSVConvertor = (
  JSONData,
  ReportTitle,
  excelFor,
  columnNames
) => {
  let data = {};
  if (excelFor === "Complaints") {
    data = getColumnsForComplaints(JSONData);
  } else if (excelFor === "Reviews") {
    data = JSONData;
  } else {
    data = JSONData;
  }

  var arrData = typeof data != "object" ? JSON.parse(data) : data;

  var CSV = "";
  CSV += ReportTitle + "\r\n\n";

  var row = "";

  // column names
  for (var index in arrData[0]) {
    const capitalizedIndex = index.charAt(0).toUpperCase() + index.slice(1);
    row += capitalizedIndex + ",";
  }
  let keys = Object.keys(arrData[0]);
  row = row.slice(0, -1);
  if (columnNames) {
    CSV += columnNames + "\r\n";
  } else {
    CSV += row + "\r\n";
  }

  for (var i = 0; i < arrData.length; i++) {
    var row = "";

    keys.forEach((key) => {
      row += '"' + arrData[i][key] + '",';
    });

    row.slice(0, row.length - 1);
    CSV += row + "\r\n";
  }

  if (CSV == "") {
    alert("Invalid data");
    return;
  }

  var fileName = "";
  fileName += ReportTitle.replace(/ /g, "");
  var uri = "data:text/csv;charset=utf-8,\uFEFF" + encodeURIComponent(CSV);
  var link = document.createElement("a");
  link.href = uri;

  link.style = "visibility:hidden";
  link.download = fileName + ".csv";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const JSONToCSVConvertorRevamp = (
    JSONData,
    ReportTitle,
    excelFor,
    columnNames
  ) => {
    let data = {};
    data = JSONData;
    
    var arrData = typeof data != "object" ? JSON.parse(data) : data;
  
    var CSV = "";
    CSV += ReportTitle + "\r\n\n";
  
    var row = "";
  
    // column names
    for (var index in arrData[0]) {
      const capitalizedIndex = index.charAt(0).toUpperCase() + index.slice(1);
      row += capitalizedIndex + ",";
    }
    let keys = Object.keys(arrData[0]);
    row = row.slice(0, -1);
    if (columnNames) {
      CSV += columnNames + "\r\n";
    } else {
      CSV += row + "\r\n";
    }
  
    for (var i = 0; i < arrData.length; i++) {
      var row = "";
  
      keys.forEach((key) => {
        row += '"' + arrData[i][key] + '",';
      });
  
      row.slice(0, row.length - 1);
      CSV += row + "\r\n";
    }
  
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
  
    var fileName = "";
    fileName += ReportTitle.replace(/ /g, "");
    var uri = "data:text/csv;charset=utf-8,\uFEFF" + encodeURIComponent(CSV);
    var link = document.createElement("a");
    link.href = uri;
  
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
  
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

const getColumnsForComplaints = (fullData) => {
  let data = JSON.parse(JSON.stringify(fullData));

  data.forEach((record) => {
    record["Contact_Name"] =
      record.contact && record.contact.name ? record.contact.name : "";
    record["Contact_Email"] =
      record.contact && record.contact.email ? record.contact.email : "";
    record["Contact_number"] =
      record.contact && record.contact.number ? record.contact.number : "";
    let notes = "";
    if (record.notes) {
      record.notes.forEach((note) => {
        notes += note["note_text"] + " , ";
      });
    }
    record["Notes"] = notes;
    record["Comment"] =
      record["commentText"] === null
        ? ""
        : removeBreakingCharacters(record["commentText"]);
    record["Comment_Audio"] =
      record["CommentAudio"] === null
        ? ""
        : removeBreakingCharacters(record["commentAudio"]);

    delete record["submittedAndId"];
    delete record["contact"];
    delete record["comment"];
    delete record["notes"];
    delete record["commentText"];
    delete record["commentAudio"];
    delete record["hasNote"];
  });
  return data;
};

export const removeBreakingCharacters = (oldText) => {
    // console.log(oldText, 'whyyyyyyyyyyyyy')
  const newText =
    oldText !== null && oldText !== undefined
      ? oldText.replaceAll('"', "")
      : null;

  return newText;
};
